import { Outlet, Link } from "react-router-dom";
import {
     MarketingFooter, NavBarHeader, NavBarHeader2
} from '../ui-components';

function Layout() {
    return (
        <div>
            <NavBarHeader />
            {/* <NavBarHeader2 /> */}
            <Outlet />
            <MarketingFooter />
        </div>
    );
}

export default Layout;
import {
    View
} from '@aws-amplify/ui-react';

function PrivacyPolicy() {
    return (
        <View
        maxWidth="90%" 
        padding="2rem" >
            <h2>Privacy Policy</h2>

            <h2>1.What data do we collect</h2>
            <p>1.1 Book2Vid does not collect your data. Our service acts like a bridge that makes it possible to publish videos to TikTok directly. We may store data that is necessary for providing a better service to you, such as logs and usage statistics.</p>

            <p>1.2 Book2Vid's OAuth Platform. When an account is logged in for the first time on our OAuth platform: basic profile information and the account's session cookies are securely stored on our database. The account owner always has full control over the data and can choose to delete everything from our database in just a few clicks. In the end, the decision to trust a third party application it's always up to the account owner.</p>


            <h2>2.How do we share the data we collect</h2>
            <p>We don't share the Book2Vid's accounts data with anyone without the account owner's explicit consent. This includes sharing information with other Book2Vid apps that the account owner has chosen to authorize.</p>


            <h2>3.The data rights</h2>
            <p>Notwithstanding anything to the contrary, Book2Vid shall have the right collect and analyze data and other information relating to the provision, use and performance of various aspects of the Services and related systems and technologies (including, without limitation, information concerning Customer Data and data derived therefrom), and Book2Vid will be free (during and after the term hereof) to (i) use such information and data to improve and enhance the Services and for other development, diagnostic and corrective purposes in connection with the Services and other Book2Vid offerings, and (ii) disclose such data solely in aggregate or other de-identified form in connection with its business. No rights or licenses are granted except as expressly set forth herein.</p>


            <h2>4.Changes to this policy</h2>
            <p>We may change this policy from time to time, and if we do we’ll post any changes on this page. By continuing to use Book2Vid after those changes are in effect, you agree to the revised policy. If the changes are significant, we may provide more prominent notice or obtain your consent as required by law.</p>
        </View>
    );
}

export default PrivacyPolicy;
/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "ttauthapi2",
            "endpoint": "https://zr1zzzm9i0.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:f727be60-e1e8-4f25-8ea8-2bc6e54bfa8d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_w1y5Hv8zw",
    "aws_user_pools_web_client_id": "kj90t61vdtsrsik8dn038u9dn",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "book2vidSchedule-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "book2vidusers-staging",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "book2vid2c9f0f012ff824609be2fef006d4c6c4e193640-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;

import { useEffect, useState } from "react"
import { Authenticator, Button, Heading, Link, View } from '@aws-amplify/ui-react';
import { CTASection } from '../ui-components';
import '@aws-amplify/ui-react/styles.css';
import Cookies from 'universal-cookie';



function TikTokSignIn() {

    const createCsrfState = () => Math.random().toString(36).substring(7);
    const csrfState = createCsrfState(); 
    const cookies = new Cookies();
    cookies.set('csrfState', csrfState, { maxAge: 60000 });
    // console.log(cookies.get('csrfState')); 

    let url = 'https://www.tiktok.com/v2/auth/authorize/';

    url += `?client_key=awsns3ec1mua9xjs`;
    url += '&scope=user.info.basic,video.publish,video.upload';
    url += '&response_type=code';
    url += `&redirect_uri=${encodeURIComponent('https://www.book2vid.com/tt-callback/')}`;
    url += '&state=' + csrfState;

    // const [users, setUsers] = useState([]);

    // const fetchUserData = () => {
    //     fetch("https://jsonplaceholder.typicode.com/users")
    //       .then(response => {
    //         return response.json()
    //       })
    //       .then(data => {
    //         setUsers(data)
    //       })
    // }

    // useEffect(() => {
    //     fetchUserData()
    //   }, [])

    return (
        <Authenticator>
        
                {({ signOut, user }) => (
                    <View
                    maxWidth="100%" 
                    margin="6rem" >
                        <Heading
                            width='30vw'
                            level={3} 
                            marginBottom="1rem"
                        >   
                            TikTok Authorization
                        </Heading>
                        <Heading
                            width='40vw'
                            level={4} 
                            marginBottom="1rem"
                        >
                            Connect with TikTok and post your videos
                        </Heading>
                        <Link href={url}>
                            <Button 
                                variation="primary"
                                size="large"
                            >Sign in With TikTok</Button>
                        </Link>
                    </View>
                )}
            
        </Authenticator>
    );
}

export default TikTokSignIn;
import {
    View
} from '@aws-amplify/ui-react';
import { Authenticator, Button } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';


function AppSignIn() {
    return (
        <Authenticator>
                {({ signOut, user }) => (
                    <View
                    maxWidth="90%" 
                    padding="2rem" >
                        <h1>Hello {user.username}</h1>
                        <Button onClick={signOut}>Sign out</Button>
                    </View>
                )}
            
        </Authenticator>
    );
}

export default AppSignIn;
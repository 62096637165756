import { Amplify, API, Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';
import { Alert, Button, Heading, Image, Loader, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { useSearchParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import {useEffect, useState} from 'react';

Amplify.configure(awsconfig);

Amplify.configure({
    API: {
      endpoints: [
        {
          name: 'ttauthapi2',
          endpoint: 'https://zr1zzzm9i0.execute-api.us-east-1.amazonaws.com/staging',
          custom_header: async () => {
            // return { Authorization: 'token' };
            // Alternatively, with Cognito User Pools use this:
            return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
            // return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
          }
        }
      ]
    }
  });

let username;

let alert = <Alert
  variation="info"
  isDismissible={false}
  hasIcon={true}
  heading="Loading"
  >
  Authenticating into TikTok
  <Loader 
    variation="linear"
  />
</Alert>;
let profileDisplay;

Auth.currentSession().then(res=>{
  let accessToken = res.getAccessToken()
  let jwt = accessToken.getJwtToken()
      
  //You can print them to see the full objects
  //   console.log(`myAccessToken: ${JSON.stringify(accessToken)}`)
  //   console.log(`myJwt: ${jwt}`)
  let decoded = jwt_decode(jwt);
  console.log(decoded, 'decoded');
  username = decoded.username;
});

function TikTokCallback() {
    const [appLogin, setAppLogin] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    let code = searchParams.get('code');
    code = decodeURIComponent(code);
    console.log(code, 'code');
      
    useEffect(() => {
        // console.log("Use Effect ran");
        
        async function postData() {
          // original API Gateway REST API
            const apiName = 'ttauthapi2';
            const path = '/users';
            const myInit = {
                body: {
                    "user": username,
                    "code": code
                  }, // replace this with attributes you need
            };
            let apiResponse;
            try {
              apiResponse = await API.post(apiName, path, myInit);
            } catch(err) {
              apiResponse = err;
            }
            if (apiResponse.success) {
              alert = <Alert
                variation="success"
                isDismissible={true}
                hasIcon={true}
                heading="Success"
              >
                TikTok successfully authenticated
              </Alert>;
            } else {
              alert = <Alert
                variation="error"
                isDismissible={true}
                hasIcon={true}
                heading="Error"
              >
                Error Authenticating to TikTok
              </Alert>;
            }
            profileDisplay = <div>
              <Heading
                  width='40vw'
                  level={4} 
                  marginBottom="1rem"
              >
                  Connected with TikTok
              </Heading>
              <Image
                alt="TikTok Profile"
                src={apiResponse.avatar_url}
                objectFit="initial"
                objectPosition="50% 50%"
                backgroundColor="initial"
                height="50px"
                width="50px"
                opacity="100%"
                onClick={() => alert('📸 Say cheese!')}
                marginBottom="1rem"
              />  
              <h3>Hello, {apiResponse.display_name}</h3>
              <Button variation='primary'>
                Go Create a Video
              </Button>
            </div>;
            console.log(apiResponse, 'apiResponse');
            setAppLogin(apiResponse);
            return apiResponse;
        }
        postData();
    }, [code]);

    // Example of how to make other calls:
    // GET ALL DATA (SCAN)
      // {
      //   "httpMethod": "GET",
      //   "path": "/users",
      //   "headers": {
      //     "Content-Type": "application/json"
      //   }
      // }
    // QUERYING ALL OBJECTS FOR A SINGLE USER
      // {
      //   "httpMethod": "GET",
      //   "path": "/users/david",
      //   "headers": {
      //     "Content-Type": "application/json"
      //   },
      //   "params": "{\"user\":\"david\"}"
      // }
    // CALLING AN INDIVIDUAL OBJECT
      // {
      //   "httpMethod": "GET",
      //   "path": "/users/object/david/TIKTOK-_000EzzAAb8ylRyOJrFqac82gzMj_F9OLgnR",
      //   "headers": {
      //     "Content-Type": "application/json"
      //   },
      //   "params": "{\"user\":\"david\",\"appLogin\":\"TIKTOK-_000EzzAAb8ylRyOJrFqac82gzMj_F9OLgnR\"}"
      // }
    // DELETE AN INDIVIDUAL OBJECT
      // {
      //   "httpMethod": "DELETE",
      //   "path": "/users/object/davidx/test", // test is the appLogin/sortKey value
      //   "headers": {
      //     "Content-Type": "application/json"
      //   }
      // }
    

    return (
        <View
        maxWidth="100%" 
        margin="1rem" >
          {alert}
          <View
          maxWidth="100%"
          margin="4rem">
            <Heading
                  width='30vw'
                  level={3} 
                  marginBottom="1rem"
              >   
                  TikTok Authorization
              </Heading>
              {profileDisplay}
            </View>
        </View>  
    );
}

export default TikTokCallback;
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ComingSoon from "./pages/ComingSoon";
import AppSignIn from "./pages/AppSignIn";
import TikTokSignIn from "./pages/TikTokSignIn";
import TikTokCallback from "./pages/TikTokCallback";
import VideoCreated from "./pages/VideoCreated";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="tos" element={<TermsOfService />} />
            <Route path="privacy" element={<PrivacyPolicy />} />
            <Route path="coming-soon" element={<ComingSoon />} />
            <Route path="my-account" element={<AppSignIn />} />
            <Route path="tt-auth" element={<TikTokSignIn />} />
            <Route path="tt-callback" element={<TikTokCallback />} />
            <Route path="video-created" element={<VideoCreated />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
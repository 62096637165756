import { Amplify, API, Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';
// import { useNavigate } from 'react-router-dom';
import { Alert, Button, Card, CheckboxField, Divider, Grid, Heading, Image, Link, Loader, SelectField, SwitchField, Text, TextAreaField, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Tooltip } from 'react-tooltip'
import {useEffect, useState} from 'react';

Amplify.configure(awsconfig);

let alert = <Alert
  variation="info"
  isDismissible={false}
  hasIcon={true}
  heading="Loading"
  >
  Creating Video
  <Loader 
    variation="linear"
  />
</Alert>;

let formAlert;

// TikTok User Info
// get info API from TikTok
Amplify.configure({
  API: {
    endpoints: [
      {
        name: 'ttauthapi2',
        endpoint: 'https://zr1zzzm9i0.execute-api.us-east-1.amazonaws.com/staging',
        custom_header: async () => {
          // return { Authorization: 'token' };
          // Alternatively, with Cognito User Pools use this:
          return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
          // return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        }
      }
    ]
  }
});

let profileDisplay;


// TikTok Post Form
let brandedContentTooltip = <Tooltip id="branded-content-tip" />;
let musicTerms = <View>
  By posting, you agree to TikTok's <Link href='https://www.tiktok.com/legal/page/global/music-usage-confirmation/en' isExternal={true}>Music Usage Confirmation</Link>.
</View>;
let brandedTerms = <View>
  By posting, you agree to TikTok's <Link href='https://www.tiktok.com/legal/page/global/bc-policy/en' isExternal={true}>Branded Content Policy</Link> and <Link href='https://www.tiktok.com/legal/page/global/music-usage-confirmation/en' isExternal={true}>Music Usage Confirmation</Link>.
</View>;

function VideoCreated() { 
  const [appLogin, setAppLogin] = useState(false);
  const [showMainAlert, setShowMainAlert] = useState(true);
  const [showVideoOptions, setShowVideoOptions] = useState(true);
  const [showProfile, setShowProfile] = useState(false);
  const [postForm, setPostForm] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [pubilshed, setPublished] = useState(false);
  const [pubilshError, setPublishError] = useState(false);
  // form content
  const [brandedContent, setBrandedContent] = useState(false);
  const [brandType, setBrandType] = useState({your_brand: false, branded_content: false});
  const [privacyStatus, setPrivacyStatus] = useState('status');
  const [videoCaption, setVideoCaption] = useState('');
  const [videoCaptionLength, setVideoCaptionLength] = useState(0);
  const [allowComments, setAllowComments] = useState(false);
  const [allowDuet, setAllowDuet] = useState(false);
  const [allowStitch, setAllowStitch] = useState(false);

  // const navigate = useNavigate();
  // function navPostToTikTok() {
  //   navigate('/tiktok-post');
  // }

  // display main alert
  function toggleMainAlert(status){
    setShowMainAlert(status);
  }

  // show Social Media profile
  function toggleProfileDisplay(status){
    setShowProfile(status);
  }

  // Form Updates
  // update video caption
  function updateVideoCaption(e){
    let captionCount = e.target.value.length;
    setVideoCaption(e.target.value);
    setVideoCaptionLength(captionCount);
    if (videoCaptionLength > 150) {
      setVideoCaption(videoCaption.slice(0,149));
      setVideoCaptionLength(150);
    }
  }

  // display form
  function goToPostToTikTok(){
    toggleMainAlert(false);
    setPostForm(true);
    setShowVideoOptions(false);
    profileDisplay = <Alert
      variation="info"
      isDismissible={false}
      hasIcon={true}
      heading="Loading"
      >
        Getting Latest User Info from TikTok
      <Loader 
        variation="linear"
      />
    </Alert>;
    getUserData();
  }

  // update privacy status
  function updatePrivacyStatus(e){
    setPrivacyStatus(e.target.value)
  }

  // if brand content gets selected
  function updateBrandedContent(e) {
    setBrandedContent(e.target.checked);
    if (!e.target.checked) {
      setBrandType({
        'branded_content': false,
        'your_brand': false
      });
    }
  }

  // when brand types are selected
  function updateBrandType(e) {
    let name = e.target.name;
    let checked = e.target.checked    
    if (name == 'your_brand') {
      setBrandType({
        ...brandType,
        'your_brand': checked
      });
    } else if (name == 'branded_content') {
      setBrandType({
        ...brandType,
        'branded_content': checked
      });
    }
  }

  // Get user info
  async function getUserData() {
    toggleProfileDisplay(true);
    const apiName = 'ttauthapi2';
    const path = '/users/object/david/TIKTOK-_000EzzAAb8ylRyOJrFqac82gzMj_F9OLgnR';
    const myInit = {
        headers: {"Content-Type": "application/json"}
    };
    let apiResponse;
    try {
      apiResponse = await API.get(apiName, path, myInit);
    } catch(err) {
      apiResponse = err;
    }
    console.log(apiResponse, 'apiResponse');
    if (apiResponse.user) {
      profileDisplay = <View maxWidth="100%">
          <Card>
            <Grid
              templateColumns="1fr 3fr 1fr"
            >
              <Card
                columnStart="1"
                columnEnd="2"
              >
                <Image
                  alt="TikTok Profile"
                  src={apiResponse.avatar_url}
                  objectFit="initial"
                  objectPosition="50% 50%"
                  backgroundColor="initial"
                  height="50px"
                  width="50px"
                  opacity="100%"
                  marginBottom="1rem"
                />  
              </Card>
              <Card
                columnStart="2"
                columnEnd="3"
                alignContent="center"
                justifyContent="left"
              >
                <Heading
                    level={5}
                  >
                    {apiResponse.display_name}
                </Heading>
              </Card>
              <Card
                columnStart="3"
                columnEnd="-1"
              >
                <Button
                  variation="primary"
                  backgroundColor="rgb(149, 4, 4)" 
                  color="white"
                  size="small"
                >
                  Sign Out
                </Button>
              </Card>
            </Grid>
            <Divider orientation="horizontal" />
          </Card>
        </View>;
    } else {
      alert = <Alert
        variation="error"
        isDismissible={true}
        hasIcon={true}
        heading="Error"
      >
        Error Getting User Info from TikTok
      </Alert>;
    }
    setAppLogin(apiResponse);
    return apiResponse;
}

function startPublishing(){
  setPostForm(false);
  setPublishing(true);
}

// cancel upload
function cancelPublishing(){
  // TODO actually cancel the upload (when it exists)
  formAlert = <Alert
      variation="warning"
      isDismissible={true}
      hasIcon={true}
      heading="Video Upload Canceled"
    >
    </Alert>
  setPostForm(true);
  setPublishing(false);
}

// TODO upload finished
function uploadFinished(){
  setPublishing(false);
  setPublished(true);
}

// TODO upload error
function uploadError(){
  setPublishing(false);
  setPublishError(true);
}

//   useEffect(() => {      
    
//     getData();
// }, []);
  
  alert = <Alert
    variation="success"
    isDismissible={true}
    hasIcon={true}
    heading="Success"
    >
    Video Created
  </Alert>;


  let videoDisplay = <Card
    maxWidth="300px"
    variation="outlined">
      <iframe src='https://vids.book2vid.com/david/TIKTOK-_000EzzAAb8ylRyOJrFqac82gzMj_F9OLgnR/text2-images13-music4.mp4'
          width='270'
          height='480'
          frameBorder='1'
          allow='autoplay; encrypted-media'
          allowFullScreen
          title='video'
      />
      <Text
          variation="primary"
          as="p"
          lineHeight="1.5em"
          fontWeight={400}
          fontSize="1.5em"
          fontStyle="normal"
          textDecoration="none"
          width="30vw"
        >
          Book1-Chapter1-Scene1
      </Text>
      <Text
          variation="primary"
          as="p"
          lineHeight="1em"
          fontWeight={300}
          fontSize="1em"
          fontStyle="normal"
          textDecoration="none"
          width="30vw"
        >
          Duration: 0:11
      </Text>
  </Card>;

  let videoOptions = <View>
    <Card></Card>
    <Card></Card>
    <Card>
      <Button
        variation="primary"
        size="large"
      >Download</Button>
    </Card>
    <Card>
      <Button
        size="large"
      >Edit</Button>
    </Card>
    <Card>
      <Button
        variation="primary"
        backgroundColor="purple" 
        color="white"
        size="large"
        onClick={goToPostToTikTok}
      >Post to TikTok</Button>
    </Card>
  </View>

  // TikTok Post Form
  let optionsForm = <View 
  >
    {formAlert}
    <View margin="1rem" display={appLogin ? "" : "none"}>
      <TextAreaField
        label="Caption"
        name="video_caption"
        placeholder="Add a caption that describes your video"
        rows={3}
        value={videoCaption}
        onChange={updateVideoCaption}
      />
      <Text
        color={videoCaptionLength >= 150 ? "red": ''}
      >
        {videoCaptionLength} / 150
      </Text>
      <SelectField
        label="Who can view this video?"
        marginTop="0.5rem"
        defaultValue="status"
        value={privacyStatus}
        onChange={updatePrivacyStatus}
      >
          <option value="status" disabled>Select Privacy Status</option>
          <option value="everyone">Everyone</option>
          <option value="friends">Friends</option>
          <option 
            value="self" 
            disabled={brandType.branded_content}
          >{brandType.branded_content ? 'Only you (cannot be selected when "Branded Content" is selected)': 'Only you'}</option>
      </SelectField>
      <Heading
        level={6}
        marginTop="0.5rem"
      >
        Allow Users To:
      </Heading>
      <CheckboxField
        label="Comment"
        name="comment"
        checked={allowComments}
        onChange={(e) => setAllowComments(e.target.checked)}
      />
      <CheckboxField
        label="Duet"
        name="duet"
        checked={allowDuet}
        onChange={(e) => setAllowDuet(e.target.checked)}
      />
      <CheckboxField
        label="Stitch"
        name="stitch"
        checked={allowStitch}
        onChange={(e) => setAllowStitch(e.target.checked)}
      />
      <View
        backgroundColor="lightGray"
        paddingBottom="0.5em"
      >
        <SwitchField
          size="large"
          trackColor="#0D1A26"
          isDisabled={false}
          label="Disclose Post Content"
          labelPosition="start"
          isChecked={brandedContent}
          onChange={updateBrandedContent}
        />
        <Text
            variation="primary"
            as="p"
            lineHeight="1em"
            fontWeight={200}
            fontSize="1em"
            marginLeft="0.5em"
            fontStyle="normal"
            textDecoration="none"
          >
            Let others know if this post promotes a brand, product or service.
        </Text>
        <View display={brandedContent ? "" : "none"}>
          <Text
            margin="0.5em"
          >
            Choose this to disclose that this video promotes goods or services in exchange for something of value. Your video could promote yourself, a third party, or both.
          </Text>
          <CheckboxField
            label="Your brand"
            name="your_brand"
            value="yes"
            marginLeft="0.5em"
            checked={brandType.your_brand}
            onChange={updateBrandType}
          />
          <Text marginLeft="0.5em">
            You are promoting yourself or your own business.
          </Text>
          <View>
            <CheckboxField
              label={privacyStatus == 'self' ? 'Branded Content (Cannot be selected if Privacy Status is set to "Only You")' : 'Branded Content'}
              name="branded_content"
              value="yes"
              marginLeft="0.5em"
              marginTop="1em"
              disabled={privacyStatus == 'self' ? true : false}
              checked={brandType.branded_content}
              onChange={updateBrandType}
            />
            <Text marginLeft="0.5em">
              You are promoting another brand or third party.
            </Text>

          </View>
          <Alert
            display={brandType.branded_content ? "" : brandType.your_brand ? "" : "none"}
            margin="0.5em"
            variation="info"
            isDismissible={false}
            hasIcon={true}
          >
            Your video will be labeled “{brandType.branded_content ? "Paid partnership" : brandType.your_brand ? "Promotional content" : ""}”. <br/>
            This cannot be changed once your video is posted.
          </Alert>
          <Text marginLeft="0.5em" marginTop="1em">
            {brandType.branded_content ? brandedTerms : brandType.your_brand ? musicTerms : ""}
          </Text>
        </View>
      </View>
      <Button
        variation="primary"
        marginTop="1em"
        disabled={privacyStatus == 'status' ? true: brandedContent ? brandType.branded_content ? false : brandType.your_brand ? false : true : false}
        data-tooltip-id="branded-content-tip"
        data-tooltip-content={privacyStatus == 'status' ? "Set a Privacy Status" : "You need to indicate if your content promotes yourself, a third party, or both."} 
        onClick={startPublishing}
      >
        Publish to TikTok
      </Button>
      {privacyStatus == 'status' ? brandedContentTooltip: brandedContent ? brandType.branded_content ? '' : brandType.your_brand ? '' : brandedContentTooltip : ''}

    </View>
  </View>;

  // Uploading video to TikTok
  let publishingToTikTokStatus = <View>
    <Alert
      variation="info"
      isDismissible={false}
      hasIcon={true}
      heading="Your video is currently being published to TikTok"
      onClick={uploadFinished}
    >
      <Loader 
        variation="linear"
      />
    </Alert>
    <Button
      variation="primary"
      backgroundColor="rgb(149, 4, 4)" 
      marginTop="1rem"
      color="white"
      size="large"
      onClick={cancelPublishing}
    >
      Cancel Upload
    </Button>
    <Text
        onClick={uploadError}
      >
        x
    </Text>
  </View>;

  // Video Upload - Success
  let videoUploadSuccess = <View>
    <Alert
      variation="success"
      isDismissible={true}
      hasIcon={true}
      heading="Success"
    >
      Your video was successfully uploaded to TikTok
    </Alert>
    <Heading
        level={6}
        marginTop="0.5rem"
      >
        Video Id: xxoxxoxxo
    </Heading>
    <Link
      href="https://vm.tiktok.com/ZT8BcLSEP/"
      isExternal={true}
    >
      <Button
        variation="primary"
        marginTop="1rem"
        size="large"
      >
        View your video on TikTok
      </Button>
    </Link>
  </View>;

  // Video Upload - Error - TODO - ingest error message
  let videoUploadError = <View>
    <Alert
      variation="error"
      isDismissible={false}
      hasIcon={true}
      heading="Error"
    >
      We’re sorry, but at this time your video could not be uploaded to TikTok for the following reasons:
      <br/><br/>
      Your request is blocked due to exceeding the API rate limit.
      <br/><br/>
      Please reach out to us at Book2Vid to get more support:
      <br/> 
      <Button
        variation="primary"
        marginTop="1rem"
        size="large"
      >
        Contact Book2Vid
      </Button>
    </Alert>
  </View>;

  
  return (
      <View
      maxWidth="100%" 
      margin="1rem" >
        {showMainAlert ? alert : ''}
        <Grid
          columnGap="0.5rem"
          templateColumns="1fr 2fr"
          maxWidth="80%"
          margin="4rem"
        >
          <View
            columnStart="1"
            columnEnd="2"
          >
            {videoDisplay}
          </View>
          <View
            columnStart="2"
            columnEnd="-1"
          >
            <Grid
            >
              { showVideoOptions ? videoOptions : ''}
              { showProfile ? profileDisplay : ''}
              { postForm ? optionsForm : ''}
              { publishing ? publishingToTikTokStatus: ''}
              { pubilshed ? videoUploadSuccess: ''}
              { pubilshError ? videoUploadError: ''}
            </Grid>
          </View>
        </Grid>
      </View>  
  );
}

export default VideoCreated;
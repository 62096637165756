/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function LogoWithText(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    { overrides: { LogoWithText: {} }, variantValues: { color: "neutral" } },
    {
      overrides: {
        LogoWithText: {
          paths: [
            {
              d: "M0 14.7933L0 0.682131L5.40118 0.682131C6.42079 0.682131 7.26817 0.842903 7.94332 1.16445C8.62306 1.4814 9.13057 1.91548 9.46585 2.4667C9.80572 3.01791 9.97565 3.64263 9.97565 4.34084C9.97565 4.91502 9.86542 5.40652 9.64497 5.81534C9.42451 6.21957 9.12827 6.548 8.75625 6.80064C8.38423 7.05328 7.96858 7.23473 7.5093 7.34497L7.5093 7.48277C8.00992 7.51033 8.48987 7.66422 8.94915 7.94442C9.41303 8.22003 9.79194 8.61047 10.0859 9.11576C10.3798 9.62104 10.5268 10.232 10.5268 10.9486C10.5268 11.6789 10.35 12.3358 9.99632 12.9192C9.64267 13.4979 9.1099 13.955 8.39801 14.2903C7.68612 14.6256 6.79052 14.7933 5.7112 14.7933L0 14.7933ZM2.55592 12.6573L5.30473 12.6573C6.23249 12.6573 6.90074 12.4805 7.30951 12.1268C7.72286 11.7685 7.92954 11.3091 7.92954 10.7487C7.92954 10.3307 7.8262 9.95406 7.61952 9.61874C7.41285 9.27882 7.1189 9.0124 6.7377 8.81948C6.35649 8.62196 5.9018 8.5232 5.37362 8.5232L2.55592 8.5232L2.55592 12.6573ZM2.55592 6.68351L5.08428 6.68351C5.52519 6.68351 5.92247 6.60312 6.27612 6.44235C6.62977 6.27699 6.90763 6.04502 7.10972 5.74644C7.3164 5.44327 7.41974 5.08498 7.41974 4.67157C7.41974 4.12494 7.22684 3.67478 6.84104 3.32108C6.45983 2.96739 5.89262 2.79054 5.13939 2.79054L2.55592 2.79054L2.55592 6.68351Z",
              fill: "rgba(64,170,191,1)",
              fillRule: "nonzero",
              style: { transform: "translate(29px, 3.09px)" },
            },
            {
              d: "M17.2559 15C16.2225 15 15.3269 14.7726 14.5691 14.3179C13.8112 13.8631 13.2234 13.2269 12.8054 12.4093C12.3921 11.5916 12.1854 10.6362 12.1854 9.54295C12.1854 8.4497 12.3921 7.49196 12.8054 6.66973C13.2234 5.8475 13.8112 5.209 14.5691 4.75425C15.3269 4.2995 16.2225 4.07212 17.2559 4.07212C18.2893 4.07212 19.1849 4.2995 19.9427 4.75425C20.7005 5.209 21.2861 5.8475 21.6995 6.66973C22.1174 7.49196 22.3264 8.4497 22.3264 9.54295C22.3264 10.6362 22.1174 11.5916 21.6995 12.4093C21.2861 13.2269 20.7005 13.8631 19.9427 14.3179C19.1849 14.7726 18.2893 15 17.2559 15ZM17.2697 13.0018C17.83 13.0018 18.2985 12.848 18.6751 12.5402C19.0517 12.2278 19.3318 11.8098 19.5156 11.2862C19.7039 10.7625 19.798 10.1791 19.798 9.53606C19.798 8.88838 19.7039 8.30271 19.5156 7.77905C19.3318 7.2508 19.0517 6.8305 18.6751 6.51814C18.2985 6.20579 17.83 6.04961 17.2697 6.04961C16.6956 6.04961 16.2179 6.20579 15.8367 6.51814C15.4601 6.8305 15.1776 7.2508 14.9893 7.77905C14.8056 8.30271 14.7137 8.88838 14.7137 9.53606C14.7137 10.1791 14.8056 10.7625 14.9893 11.2862C15.1776 11.8098 15.4601 12.2278 15.8367 12.5402C16.2179 12.848 16.6956 13.0018 17.2697 13.0018Z",
              fill: "rgba(64,170,191,1)",
              fillRule: "nonzero",
              style: { transform: "translate(29px, 3.09px)" },
            },
            {
              d: "M29.0589 15C28.0255 15 27.1299 14.7726 26.3721 14.3179C25.6143 13.8631 25.0264 13.2269 24.6084 12.4093C24.1951 11.5916 23.9884 10.6362 23.9884 9.54295C23.9884 8.4497 24.1951 7.49196 24.6084 6.66973C25.0264 5.8475 25.6143 5.209 26.3721 4.75425C27.1299 4.2995 28.0255 4.07212 29.0589 4.07212C30.0923 4.07212 30.9879 4.2995 31.7457 4.75425C32.5035 5.209 33.0891 5.8475 33.5025 6.66973C33.9204 7.49196 34.1294 8.4497 34.1294 9.54295C34.1294 10.6362 33.9204 11.5916 33.5025 12.4093C33.0891 13.2269 32.5035 13.8631 31.7457 14.3179C30.9879 14.7726 30.0923 15 29.0589 15ZM29.0727 13.0018C29.633 13.0018 30.1015 12.848 30.4781 12.5402C30.8547 12.2278 31.1349 11.8098 31.3186 11.2862C31.5069 10.7625 31.601 10.1791 31.601 9.53606C31.601 8.88838 31.5069 8.30271 31.3186 7.77905C31.1349 7.2508 30.8547 6.8305 30.4781 6.51814C30.1015 6.20579 29.633 6.04961 29.0727 6.04961C28.4986 6.04961 28.0209 6.20579 27.6397 6.51814C27.2631 6.8305 26.9806 7.2508 26.7923 7.77905C26.6086 8.30271 26.5168 8.88838 26.5168 9.53606C26.5168 10.1791 26.6086 10.7625 26.7923 11.2862C26.9806 11.8098 27.2631 12.2278 27.6397 12.5402C28.0209 12.848 28.4986 13.0018 29.0727 13.0018Z",
              fill: "rgba(64,170,191,1)",
              fillRule: "nonzero",
              style: { transform: "translate(29px, 3.09px)" },
            },
            {
              d: "M38.5196 11.4722L38.5127 8.46119L38.9123 8.46119L42.7151 4.20992L45.6293 4.20992L40.9515 9.41893L40.4348 9.41893L38.5196 11.4722ZM36.2461 14.7933L36.2461 0.682131L38.74 0.682131L38.74 14.7933L36.2461 14.7933ZM42.8874 14.7933L39.4428 9.97703L41.1237 8.22003L45.8704 14.7933L42.8874 14.7933Z",
              fill: "rgba(64,170,191,1)",
              fillRule: "nonzero",
              style: { transform: "translate(29px, 3.09px)" },
            },
            {
              d: "M47.2638 14.7933L47.2638 12.9467L52.1621 8.14424C52.6305 7.67111 53.0209 7.2508 53.3332 6.88333C53.6455 6.51585 53.8798 6.15985 54.0359 5.81534C54.1921 5.47083 54.2702 5.10335 54.2702 4.71291C54.2702 4.26734 54.1691 3.88608 53.967 3.56913C53.765 3.24759 53.4871 2.99954 53.1334 2.82499C52.7798 2.65044 52.3779 2.56316 51.9278 2.56316C51.4639 2.56316 51.0575 2.65962 50.7084 2.85255C50.3594 3.04088 50.0884 3.3096 49.8955 3.65871C49.7072 4.00781 49.613 4.42352 49.613 4.90583L47.1811 4.90583C47.1811 4.01011 47.3855 3.23151 47.7943 2.57005C48.203 1.90859 48.7656 1.39642 49.4821 1.03353C50.2032 0.670648 51.0299 0.489205 51.9623 0.489205C52.9084 0.489205 53.7397 0.666054 54.4562 1.01975C55.1727 1.37345 55.7284 1.85806 56.1234 2.47359C56.523 3.08911 56.7227 3.79191 56.7227 4.58199C56.7227 5.11024 56.6217 5.62931 56.4196 6.13918C56.2175 6.64906 55.8616 7.21406 55.3518 7.83418C54.8466 8.4543 54.137 9.20533 53.223 10.0873L50.7911 12.5609L50.7911 12.6573L56.9363 12.6573L56.9363 14.7933L47.2638 14.7933Z",
              fill: "rgba(64,170,191,1)",
              fillRule: "nonzero",
              style: { transform: "translate(29px, 3.09px)" },
            },
            {
              d: "M61.3385 0.682131L65.0105 11.7892L65.1552 11.7892L68.8203 0.682131L71.6311 0.682131L66.6571 14.7933L63.5018 14.7933L58.5346 0.682131L61.3385 0.682131Z",
              fill: "rgba(64,170,191,1)",
              fillRule: "nonzero",
              style: { transform: "translate(29px, 3.09px)" },
            },
            {
              d: "M73.3793 14.7933L73.3793 4.20992L75.8732 4.20992L75.8732 14.7933L73.3793 14.7933ZM74.6331 2.70786C74.2381 2.70786 73.8982 2.57694 73.6135 2.31511C73.3287 2.04869 73.1864 1.72944 73.1864 1.35737C73.1864 0.980707 73.3287 0.66146 73.6135 0.399632C73.8982 0.133211 74.2381 0 74.6331 0C75.0327 0 75.3726 0.133211 75.6527 0.399632C75.9375 0.66146 76.0798 0.980707 76.0798 1.35737C76.0798 1.72944 75.9375 2.04869 75.6527 2.31511C75.3726 2.57694 75.0327 2.70786 74.6331 2.70786Z",
              fill: "rgba(64,170,191,1)",
              fillRule: "nonzero",
              style: { transform: "translate(29px, 3.09px)" },
            },
            {
              d: "M82.3646 14.9793C81.5333 14.9793 80.7892 14.7657 80.1325 14.3385C79.4757 13.9113 78.9567 13.2912 78.5755 12.4782C78.1943 11.6651 78.0037 10.6775 78.0037 9.51539C78.0037 8.33946 78.1966 7.34727 78.5824 6.53882C78.9728 5.72577 79.4986 5.11254 80.16 4.69913C80.8214 4.28112 81.5585 4.07212 82.3715 4.07212C82.9915 4.07212 83.5013 4.17777 83.9009 4.38907C84.3005 4.59577 84.6174 4.84612 84.8516 5.1401C85.0858 5.42949 85.2673 5.7028 85.3959 5.96004L85.4992 5.96004L85.4992 0.682131L88 0.682131L88 14.7933L85.5474 14.7933L85.5474 13.1259L85.3959 13.1259C85.2673 13.3831 85.0812 13.6564 84.8378 13.9458C84.5944 14.2306 84.2729 14.474 83.8733 14.6762C83.4737 14.8783 82.9708 14.9793 82.3646 14.9793ZM83.0604 12.9329C83.5886 12.9329 84.0387 12.7905 84.4107 12.5057C84.7827 12.2164 85.0652 11.8144 85.2581 11.3C85.451 10.7855 85.5474 10.186 85.5474 9.50161C85.5474 8.81718 85.451 8.22232 85.2581 7.71704C85.0698 7.21176 84.7896 6.81902 84.4176 6.53882C84.0502 6.25861 83.5978 6.11851 83.0604 6.11851C82.5047 6.11851 82.0408 6.26321 81.6688 6.5526C81.2967 6.84198 81.0166 7.24162 80.8283 7.75149C80.64 8.26137 80.5458 8.84474 80.5458 9.50161C80.5458 10.1631 80.64 10.7533 80.8283 11.2724C81.0212 11.7869 81.3036 12.1934 81.6757 12.492C82.0523 12.7859 82.5138 12.9329 83.0604 12.9329Z",
              fill: "rgba(64,170,191,1)",
              fillRule: "nonzero",
              style: { transform: "translate(29px, 3.09px)" },
            },
            {
              d: "M6.1787 5.66074C5.90337 5.63957 5.66301 5.84561 5.64184 6.12094C5.62067 6.39627 5.8267 6.63663 6.10203 6.6578C6.98487 6.72568 9.18046 7.02661 10.8716 7.67058C11.1297 7.76885 11.4186 7.6393 11.5168 7.38124C11.6151 7.12317 11.4856 6.83431 11.2275 6.73604C9.40912 6.04364 7.10634 5.73207 6.1787 5.66074Z",
              fill: "rgba(64,170,191,1)",
              fillRule: "nonzero",
              style: { transform: "translate(-4px, -1.18px)" },
            },
            {
              d: "M6.1787 9.31488C5.90337 9.29371 5.66301 9.49975 5.64184 9.77508C5.62067 10.0504 5.8267 10.2908 6.10203 10.3119C6.98487 10.3798 9.18046 10.6807 10.8716 11.3247C11.1297 11.423 11.4186 11.2934 11.5168 11.0354C11.6151 10.7773 11.4856 10.4884 11.2275 10.3902C9.40912 9.69777 7.10634 9.3862 6.1787 9.31488Z",
              fill: "rgba(64,170,191,1)",
              fillRule: "nonzero",
              style: { transform: "translate(-4px, -1.18px)" },
            },
            {
              d: "M6.1787 12.8646C5.90337 12.8434 5.66301 13.0495 5.64184 13.3248C5.62067 13.6001 5.8267 13.8405 6.10203 13.8617C6.98487 13.9295 9.18046 14.2305 10.8716 14.8744C11.1297 14.9727 11.4186 14.8432 11.5168 14.5851C11.6151 14.327 11.4856 14.0382 11.2275 13.9399C9.40912 13.2475 7.10634 12.9359 6.1787 12.8646Z",
              fill: "rgba(64,170,191,1)",
              fillRule: "nonzero",
              style: { transform: "translate(-4px, -1.18px)" },
            },
            {
              d: "M26.2159 11.7795C26.2159 14.1463 24.2963 16.065 21.9284 16.065C19.5605 16.065 17.641 14.1463 17.641 11.7795C17.641 9.41264 19.5605 7.49394 21.9284 7.49394C24.2963 7.49394 26.2159 9.41264 26.2159 11.7795ZM20.3266 9.15676C20.3266 9.06669 20.4242 9.01042 20.5022 9.05545L25.0928 11.7047C25.1709 11.7497 25.1709 11.8623 25.0929 11.9073L20.5022 14.5565C20.4242 14.6016 20.3266 14.5452 20.3266 14.4552L20.3266 9.15676Z",
              fill: "rgba(64,170,191,1)",
              fillRule: "evenodd",
              style: { transform: "translate(-4px, -1.18px)" },
            },
            {
              d: "M13.4125 2.74095C12.2913 1.59652 10.6138 0.919944 8.96168 0.52581C6.90752 0.0357552 4.7589 -0.0494209 3.39837 0.0221536C3.38969 0.0226098 3.38109 0.0232844 3.37255 0.0241717C3.35534 0.0223807 3.33787 0.0214627 3.32018 0.0214627C3.04404 0.0214627 2.82018 0.24532 2.82018 0.521463L2.82018 3.88441L0 3.88441L0 22.2375C0 22.2769 0.00455746 22.3152 0.013174 22.352C0.00492355 22.3874 0.000427723 22.4242 0.000132084 22.4621C-0.00202423 22.7382 0.220079 22.9638 0.496212 22.966L13.9681 23.0712C14.0154 23.0812 14.0644 23.0865 14.1147 23.0865L15.9295 23.0865L16.3884 23.0901C16.4102 23.0902 16.4316 23.089 16.4527 23.0865L29.3475 23.0865C29.7353 23.0865 30.0497 22.7721 30.0497 22.3843L30.0497 1.36387C30.0497 0.976067 29.7353 0.661694 29.3475 0.661694L14.1147 0.661694C13.7269 0.661694 13.4125 0.976067 13.4125 1.36387L13.4125 2.74095ZM8.72962 1.49851C6.96357 1.07719 5.10871 0.971202 3.82018 1.0062L3.82018 17.6752C5.29649 17.8651 7.43995 18.2456 9.5428 18.8322C10.8874 19.2073 12.2481 19.6752 13.4125 20.2478L13.4125 4.29647C13.3417 4.25604 13.2798 4.19769 13.2343 4.1232C12.4236 2.79725 10.6968 1.96781 8.72962 1.49851ZM13.4125 21.3729L13.4125 22.0668L1 21.9699L1 4.88441L2.82018 4.88441L2.82018 18.1657C2.82018 18.4419 3.04404 18.6657 3.32018 18.6657C3.37536 18.6657 3.42845 18.6568 3.47808 18.6403C4.9135 18.8141 7.11893 19.1942 9.27409 19.7954C10.8063 20.2228 12.2701 20.7508 13.4125 21.3729ZM16.5435 2.15232L18.9669 2.15232L18.9669 4.38825L16.5435 4.38825L16.5435 2.15232ZM23.4408 2.15232L21.0174 2.15232L21.0174 4.38825L23.4408 4.38825L23.4408 2.15232ZM25.4913 2.15232L27.9146 2.15232L27.9146 4.38825L25.4913 4.38825L25.4913 2.15232ZM18.9669 19.6671L16.5435 19.6671L16.5435 21.9031L18.9669 21.9031L18.9669 19.6671ZM21.0174 19.6671L23.4407 19.6671L23.4407 21.9031L21.0174 21.9031L21.0174 19.6671ZM27.9146 19.6671L25.4913 19.6671L25.4913 21.9031L27.9146 21.9031L27.9146 19.6671ZM14.925 5.19704L28.7893 5.19704L28.7893 18.2992L14.925 18.2992L14.925 5.19704Z",
              fill: "rgba(64,170,191,1)",
              fillRule: "evenodd",
              style: { transform: "translate(-4px, -1.18px)" },
            },
          ],
        },
      },
      variantValues: { color: "brand" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Icon
      width="127.88px"
      height="18.91px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 127, height: 18 }}
      paths={[
        {
          d: "M6.1787 5.66074C5.90337 5.63957 5.66301 5.84561 5.64184 6.12094C5.62067 6.39627 5.8267 6.63663 6.10203 6.6578C6.98487 6.72568 9.18046 7.02661 10.8716 7.67058C11.1297 7.76885 11.4186 7.6393 11.5168 7.38124C11.6151 7.12317 11.4856 6.83431 11.2275 6.73604C9.40912 6.04364 7.10634 5.73207 6.1787 5.66074Z",
          fill: "rgba(174,179,183,1)",
          fillRule: "nonzero",
          style: { transform: "translate(-5px, -2.27px)" },
        },
        {
          d: "M6.1787 9.31488C5.90337 9.29371 5.66301 9.49975 5.64184 9.77508C5.62067 10.0504 5.8267 10.2908 6.10203 10.3119C6.98487 10.3798 9.18046 10.6807 10.8716 11.3247C11.1297 11.423 11.4186 11.2934 11.5168 11.0354C11.6151 10.7773 11.4856 10.4884 11.2275 10.3902C9.40912 9.69777 7.10634 9.3862 6.1787 9.31488Z",
          fill: "rgba(174,179,183,1)",
          fillRule: "nonzero",
          style: { transform: "translate(-5px, -2.27px)" },
        },
        {
          d: "M6.1787 12.8646C5.90337 12.8434 5.66301 13.0495 5.64184 13.3248C5.62067 13.6001 5.8267 13.8405 6.10203 13.8617C6.98487 13.9295 9.18046 14.2305 10.8716 14.8744C11.1297 14.9727 11.4186 14.8432 11.5168 14.5851C11.6151 14.327 11.4856 14.0382 11.2275 13.9399C9.40912 13.2475 7.10634 12.9359 6.1787 12.8646Z",
          fill: "rgba(174,179,183,1)",
          fillRule: "nonzero",
          style: { transform: "translate(-5px, -2.27px)" },
        },
        {
          d: "M26.2159 11.7795C26.2159 14.1463 24.2963 16.065 21.9284 16.065C19.5605 16.065 17.641 14.1463 17.641 11.7795C17.641 9.41264 19.5605 7.49394 21.9284 7.49394C24.2963 7.49394 26.2159 9.41264 26.2159 11.7795ZM20.3266 9.15676C20.3266 9.06669 20.4242 9.01042 20.5022 9.05545L25.0928 11.7047C25.1709 11.7497 25.1709 11.8623 25.0929 11.9073L20.5022 14.5565C20.4242 14.6016 20.3266 14.5452 20.3266 14.4552L20.3266 9.15676Z",
          fill: "rgba(174,179,183,1)",
          fillRule: "evenodd",
          style: { transform: "translate(-5px, -2.27px)" },
        },
        {
          d: "M13.4125 2.74095C12.2913 1.59652 10.6138 0.919944 8.96168 0.52581C6.90752 0.0357552 4.7589 -0.0494209 3.39837 0.0221536C3.38969 0.0226098 3.38109 0.0232844 3.37255 0.0241717C3.35534 0.0223807 3.33787 0.0214627 3.32018 0.0214627C3.04404 0.0214627 2.82018 0.24532 2.82018 0.521463L2.82018 3.88441L0 3.88441L0 22.2375C0 22.2769 0.00455746 22.3152 0.013174 22.352C0.00492355 22.3874 0.000427723 22.4242 0.000132084 22.4621C-0.00202423 22.7382 0.220079 22.9638 0.496212 22.966L13.9681 23.0712C14.0154 23.0812 14.0644 23.0865 14.1147 23.0865L15.9295 23.0865L16.3884 23.0901C16.4102 23.0902 16.4316 23.089 16.4527 23.0865L29.3475 23.0865C29.7353 23.0865 30.0497 22.7721 30.0497 22.3843L30.0497 1.36387C30.0497 0.976067 29.7353 0.661694 29.3475 0.661694L14.1147 0.661694C13.7269 0.661694 13.4125 0.976067 13.4125 1.36387L13.4125 2.74095ZM8.72962 1.49851C6.96357 1.07719 5.10871 0.971202 3.82018 1.0062L3.82018 17.6752C5.29649 17.8651 7.43995 18.2456 9.5428 18.8322C10.8874 19.2073 12.2481 19.6752 13.4125 20.2478L13.4125 4.29647C13.3417 4.25604 13.2798 4.19769 13.2343 4.1232C12.4236 2.79725 10.6968 1.96781 8.72962 1.49851ZM13.4125 21.3729L13.4125 22.0668L1 21.9699L1 4.88441L2.82018 4.88441L2.82018 18.1657C2.82018 18.4419 3.04404 18.6657 3.32018 18.6657C3.37536 18.6657 3.42845 18.6568 3.47808 18.6403C4.9135 18.8141 7.11893 19.1942 9.27409 19.7954C10.8063 20.2228 12.2701 20.7508 13.4125 21.3729ZM16.5435 2.15232L18.9669 2.15232L18.9669 4.38825L16.5435 4.38825L16.5435 2.15232ZM23.4408 2.15232L21.0174 2.15232L21.0174 4.38825L23.4408 4.38825L23.4408 2.15232ZM25.4913 2.15232L27.9146 2.15232L27.9146 4.38825L25.4913 4.38825L25.4913 2.15232ZM18.9669 19.6671L16.5435 19.6671L16.5435 21.9031L18.9669 21.9031L18.9669 19.6671ZM21.0174 19.6671L23.4407 19.6671L23.4407 21.9031L21.0174 21.9031L21.0174 19.6671ZM27.9146 19.6671L25.4913 19.6671L25.4913 21.9031L27.9146 21.9031L27.9146 19.6671ZM14.925 5.19704L28.7893 5.19704L28.7893 18.2992L14.925 18.2992L14.925 5.19704Z",
          fill: "rgba(174,179,183,1)",
          fillRule: "evenodd",
          style: { transform: "translate(-5px, -2.27px)" },
        },
        {
          d: "M0 14.7933L0 0.682131L5.40118 0.682131C6.42079 0.682131 7.26817 0.842903 7.94332 1.16445C8.62306 1.4814 9.13057 1.91548 9.46585 2.4667C9.80572 3.01791 9.97565 3.64263 9.97565 4.34084C9.97565 4.91502 9.86542 5.40652 9.64497 5.81534C9.42451 6.21957 9.12827 6.548 8.75625 6.80064C8.38423 7.05328 7.96858 7.23473 7.5093 7.34497L7.5093 7.48277C8.00992 7.51033 8.48987 7.66422 8.94915 7.94442C9.41303 8.22003 9.79194 8.61047 10.0859 9.11576C10.3798 9.62104 10.5268 10.232 10.5268 10.9486C10.5268 11.6789 10.35 12.3358 9.99632 12.9192C9.64267 13.4979 9.1099 13.955 8.39801 14.2903C7.68612 14.6256 6.79052 14.7933 5.7112 14.7933L0 14.7933ZM2.55592 12.6573L5.30473 12.6573C6.23249 12.6573 6.90074 12.4805 7.30951 12.1268C7.72286 11.7685 7.92954 11.3091 7.92954 10.7487C7.92954 10.3307 7.8262 9.95406 7.61952 9.61874C7.41285 9.27882 7.1189 9.0124 6.7377 8.81948C6.35649 8.62196 5.9018 8.5232 5.37362 8.5232L2.55592 8.5232L2.55592 12.6573ZM2.55592 6.68351L5.08428 6.68351C5.52519 6.68351 5.92247 6.60312 6.27612 6.44235C6.62977 6.27699 6.90763 6.04502 7.10972 5.74644C7.3164 5.44327 7.41974 5.08498 7.41974 4.67157C7.41974 4.12494 7.22684 3.67478 6.84104 3.32108C6.45983 2.96739 5.89262 2.79054 5.13939 2.79054L2.55592 2.79054L2.55592 6.68351Z",
          fill: "rgba(174,179,183,1)",
          fillRule: "nonzero",
          style: { transform: "translate(28px, 2px)" },
        },
        {
          d: "M17.2559 15C16.2225 15 15.3269 14.7726 14.5691 14.3179C13.8112 13.8631 13.2234 13.2269 12.8054 12.4093C12.3921 11.5916 12.1854 10.6362 12.1854 9.54295C12.1854 8.4497 12.3921 7.49196 12.8054 6.66973C13.2234 5.8475 13.8112 5.209 14.5691 4.75425C15.3269 4.2995 16.2225 4.07212 17.2559 4.07212C18.2893 4.07212 19.1849 4.2995 19.9427 4.75425C20.7005 5.209 21.2861 5.8475 21.6995 6.66973C22.1174 7.49196 22.3264 8.4497 22.3264 9.54295C22.3264 10.6362 22.1174 11.5916 21.6995 12.4093C21.2861 13.2269 20.7005 13.8631 19.9427 14.3179C19.1849 14.7726 18.2893 15 17.2559 15ZM17.2697 13.0018C17.83 13.0018 18.2985 12.848 18.6751 12.5402C19.0517 12.2278 19.3318 11.8098 19.5156 11.2862C19.7039 10.7625 19.798 10.1791 19.798 9.53606C19.798 8.88838 19.7039 8.30271 19.5156 7.77905C19.3318 7.2508 19.0517 6.8305 18.6751 6.51814C18.2985 6.20579 17.83 6.04961 17.2697 6.04961C16.6956 6.04961 16.2179 6.20579 15.8367 6.51814C15.4601 6.8305 15.1776 7.2508 14.9893 7.77905C14.8056 8.30271 14.7137 8.88838 14.7137 9.53606C14.7137 10.1791 14.8056 10.7625 14.9893 11.2862C15.1776 11.8098 15.4601 12.2278 15.8367 12.5402C16.2179 12.848 16.6956 13.0018 17.2697 13.0018Z",
          fill: "rgba(174,179,183,1)",
          fillRule: "nonzero",
          style: { transform: "translate(28px, 2px)" },
        },
        {
          d: "M29.0589 15C28.0255 15 27.1299 14.7726 26.3721 14.3179C25.6143 13.8631 25.0264 13.2269 24.6084 12.4093C24.1951 11.5916 23.9884 10.6362 23.9884 9.54295C23.9884 8.4497 24.1951 7.49196 24.6084 6.66973C25.0264 5.8475 25.6143 5.209 26.3721 4.75425C27.1299 4.2995 28.0255 4.07212 29.0589 4.07212C30.0923 4.07212 30.9879 4.2995 31.7457 4.75425C32.5035 5.209 33.0891 5.8475 33.5025 6.66973C33.9204 7.49196 34.1294 8.4497 34.1294 9.54295C34.1294 10.6362 33.9204 11.5916 33.5025 12.4093C33.0891 13.2269 32.5035 13.8631 31.7457 14.3179C30.9879 14.7726 30.0923 15 29.0589 15ZM29.0727 13.0018C29.633 13.0018 30.1015 12.848 30.4781 12.5402C30.8547 12.2278 31.1349 11.8098 31.3186 11.2862C31.5069 10.7625 31.601 10.1791 31.601 9.53606C31.601 8.88838 31.5069 8.30271 31.3186 7.77905C31.1349 7.2508 30.8547 6.8305 30.4781 6.51814C30.1015 6.20579 29.633 6.04961 29.0727 6.04961C28.4986 6.04961 28.0209 6.20579 27.6397 6.51814C27.2631 6.8305 26.9806 7.2508 26.7923 7.77905C26.6086 8.30271 26.5168 8.88838 26.5168 9.53606C26.5168 10.1791 26.6086 10.7625 26.7923 11.2862C26.9806 11.8098 27.2631 12.2278 27.6397 12.5402C28.0209 12.848 28.4986 13.0018 29.0727 13.0018Z",
          fill: "rgba(174,179,183,1)",
          fillRule: "nonzero",
          style: { transform: "translate(28px, 2px)" },
        },
        {
          d: "M38.5196 11.4722L38.5127 8.46119L38.9123 8.46119L42.7151 4.20992L45.6293 4.20992L40.9515 9.41893L40.4348 9.41893L38.5196 11.4722ZM36.2461 14.7933L36.2461 0.682131L38.74 0.682131L38.74 14.7933L36.2461 14.7933ZM42.8874 14.7933L39.4428 9.97703L41.1237 8.22003L45.8704 14.7933L42.8874 14.7933Z",
          fill: "rgba(174,179,183,1)",
          fillRule: "nonzero",
          style: { transform: "translate(28px, 2px)" },
        },
        {
          d: "M47.2638 14.7933L47.2638 12.9467L52.1621 8.14424C52.6305 7.67111 53.0209 7.2508 53.3332 6.88333C53.6455 6.51585 53.8798 6.15985 54.0359 5.81534C54.1921 5.47083 54.2702 5.10335 54.2702 4.71291C54.2702 4.26734 54.1691 3.88608 53.967 3.56913C53.765 3.24759 53.4871 2.99954 53.1334 2.82499C52.7798 2.65044 52.3779 2.56316 51.9278 2.56316C51.4639 2.56316 51.0575 2.65962 50.7084 2.85255C50.3594 3.04088 50.0884 3.3096 49.8955 3.65871C49.7072 4.00781 49.613 4.42352 49.613 4.90583L47.1811 4.90583C47.1811 4.01011 47.3855 3.23151 47.7943 2.57005C48.203 1.90859 48.7656 1.39642 49.4821 1.03353C50.2032 0.670648 51.0299 0.489205 51.9623 0.489205C52.9084 0.489205 53.7397 0.666054 54.4562 1.01975C55.1727 1.37345 55.7284 1.85806 56.1234 2.47359C56.523 3.08911 56.7227 3.79191 56.7227 4.58199C56.7227 5.11024 56.6217 5.62931 56.4196 6.13918C56.2175 6.64906 55.8616 7.21406 55.3518 7.83418C54.8466 8.4543 54.137 9.20533 53.223 10.0873L50.7911 12.5609L50.7911 12.6573L56.9363 12.6573L56.9363 14.7933L47.2638 14.7933Z",
          fill: "rgba(174,179,183,1)",
          fillRule: "nonzero",
          style: { transform: "translate(28px, 2px)" },
        },
        {
          d: "M61.3385 0.682131L65.0105 11.7892L65.1552 11.7892L68.8203 0.682131L71.6311 0.682131L66.6571 14.7933L63.5018 14.7933L58.5346 0.682131L61.3385 0.682131Z",
          fill: "rgba(174,179,183,1)",
          fillRule: "nonzero",
          style: { transform: "translate(28px, 2px)" },
        },
        {
          d: "M73.3793 14.7933L73.3793 4.20992L75.8732 4.20992L75.8732 14.7933L73.3793 14.7933ZM74.6331 2.70786C74.2381 2.70786 73.8982 2.57694 73.6135 2.31511C73.3287 2.04869 73.1864 1.72944 73.1864 1.35737C73.1864 0.980707 73.3287 0.66146 73.6135 0.399632C73.8982 0.133211 74.2381 0 74.6331 0C75.0327 0 75.3726 0.133211 75.6527 0.399632C75.9375 0.66146 76.0798 0.980707 76.0798 1.35737C76.0798 1.72944 75.9375 2.04869 75.6527 2.31511C75.3726 2.57694 75.0327 2.70786 74.6331 2.70786Z",
          fill: "rgba(174,179,183,1)",
          fillRule: "nonzero",
          style: { transform: "translate(28px, 2px)" },
        },
        {
          d: "M82.3646 14.9793C81.5333 14.9793 80.7892 14.7657 80.1325 14.3385C79.4757 13.9113 78.9567 13.2912 78.5755 12.4782C78.1943 11.6651 78.0037 10.6775 78.0037 9.51539C78.0037 8.33946 78.1966 7.34727 78.5824 6.53882C78.9728 5.72577 79.4986 5.11254 80.16 4.69913C80.8214 4.28112 81.5585 4.07212 82.3715 4.07212C82.9915 4.07212 83.5013 4.17777 83.9009 4.38907C84.3005 4.59577 84.6174 4.84612 84.8516 5.1401C85.0858 5.42949 85.2673 5.7028 85.3959 5.96004L85.4992 5.96004L85.4992 0.682131L88 0.682131L88 14.7933L85.5474 14.7933L85.5474 13.1259L85.3959 13.1259C85.2673 13.3831 85.0812 13.6564 84.8378 13.9458C84.5944 14.2306 84.2729 14.474 83.8733 14.6762C83.4737 14.8783 82.9708 14.9793 82.3646 14.9793ZM83.0604 12.9329C83.5886 12.9329 84.0387 12.7905 84.4107 12.5057C84.7827 12.2164 85.0652 11.8144 85.2581 11.3C85.451 10.7855 85.5474 10.186 85.5474 9.50161C85.5474 8.81718 85.451 8.22232 85.2581 7.71704C85.0698 7.21176 84.7896 6.81902 84.4176 6.53882C84.0502 6.25861 83.5978 6.11851 83.0604 6.11851C82.5047 6.11851 82.0408 6.26321 81.6688 6.5526C81.2967 6.84198 81.0166 7.24162 80.8283 7.75149C80.64 8.26137 80.5458 8.84474 80.5458 9.50161C80.5458 10.1631 80.64 10.7533 80.8283 11.2724C81.0212 11.7869 81.3036 12.1934 81.6757 12.492C82.0523 12.7859 82.5138 12.9329 83.0604 12.9329Z",
          fill: "rgba(174,179,183,1)",
          fillRule: "nonzero",
          style: { transform: "translate(28px, 2px)" },
        },
      ]}
      {...getOverrideProps(overrides, "LogoWithText")}
      {...rest}
    ></Icon>
  );
}

import {
    HeroLayout2
} from '../ui-components';

function Home() {
    return (
        <div>
            <HeroLayout2 />
        </div>
    );
}

export default Home;
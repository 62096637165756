import {
    View
} from '@aws-amplify/ui-react';

function TermsOfService() {
    return (
        <View
        maxWidth="90%" 
        padding="2rem" >
            <h2>Terms of Service</h2>
            <p>
                Book2Vid is in no way endorsed or affiliated with TikTok or ByteDance. The “TikTok” name is copyright to ByteDance, Inc. Book2Vid’s products and services are provided by Book2Vid. These Terms of Service (“Terms”) govern your access to and use of Book2Vid’s website, products, and services (“Products”). 
            </p>
            <p>
                API: or "application programming interface" it’s a way for products and services to communicate and integrate with each other, providing information and fulfilling consumer requests, without needing to know how they are implemented. Each of Book2Vid and you may be referred to as a “party” or together as the “parties.” Please read these Terms carefully, and contact us if you have any questions. By accessing or using our Products, you agree to be bound by these Terms and by our Privacy Policy.
            </p>
            <h3>1. TikTok™ TOS</h3>
            <p>Where possible, Book2Vid has endeavoured to ensure our products and services adhere as closely as possible to the Terms of Service as provided by TikTok™ Inc.</p>

            <p>Book2Vid will collaborate and make its best efforts to provide assistance to you to demonstrate their compliance with any requirement under Applicable Law.</p>

            <p>By using Book2Vid’s products and services you acknowledge that you agree that you will not hold Book2Vid or Book2Vid Developer accountable for any damages or legal issues that may result from the use of our products and services.</p>

            <h3>2. USING OUR SERVICES</h3>
            <p>You may not:</p>
            <ul>
                <li>any unsolicited or unauthorised advertising, solicitations, promotional materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or any other prohibited form of solicitation</li>
                <li>authenticate fake accounts or without the owner consent</li>
            </ul>

            <h3>Book2Vid undertakes to:</h3>
            <ul>
                <li>perform the services in a professional and workmanlike manner consistent with good industry practices.</li>
                <li>promptly notify you of any problems that may arise during the performance of these Terms.</li>
                <li>Book2Vid shall ensure that these provisions are observed by its personnel.</li>
            </ul>

            <h3>3. MAINTENANCE AND ASSISTANCE</h3>
            <p>Book2Vid ensures the corrective and evolutionary maintenance of the API at no extra cost. The API maintenance can cause a temporary suspension of access to the associated services.Book2Vid ensures to correct as soon as possible the possible malfunctions which would affect the API and to make all the improvements which it would consider useful. You will benefit from the updates and functional developments of the API. Book2Vid undertakes to update and make readily available to you any information regarding such changes.</p>
            <p>Subject to the terms hereof, Book2Vid will provide you with reasonable technical support for its services. Available on business days on working hours, excluding: weekends, holidays, or other exceptional occasions which may arise.</p>



            <h3>4. COPYRIGHT POLICY</h3>
            <p>By publishing Content through Book2Vid, you represent and warrant that: the downloading, copying and use of the Content will not infringe the proprietary rights of any third party.</p>



            <h3>5. SECURITY</h3>
            <p>We care about the security of our users. While we work to protect the security of your content and account, Book2Vid cannot guarantee that unauthorized third parties will not be able to defeat our security measures. Please notify us immediately of any compromise or unauthorized use of your account.</p>

            <p>Without any limitations of your other rights and remedies, in case of any actual or reasonably suspected security incident, Book2Vid shall notify you without undue delay after becoming aware of such actual or suspected security incident, and in any case in accordance with Applicable Law.</p>

            <p>Book2Vid shall take appropriate steps, at its own expense, to (i) remedy the security incident, (ii) mitigate its effects and (iii) make any appropriate changes to prevent such security incident from recurring.</p>


            <h3>6. CONFIDENTIALITY & PROPRIETARY RIGHTS</h3>
            <p>6.1. Each party (the “Receiving Party”) understands that the other party (the “Disclosing Party”) has disclosed or may disclose business, technical or financial information relating to the Disclosing Party’s business (hereinafter referred to as “Proprietary Information” of the Disclosing Party). Proprietary Information of Book2Vid includes non-public information regarding features, functionality and performance of the Service. Proprietary Information of Customer includes non-public data provided by Customer to Book2Vid to enable the provision of the Services (“Customer Data”). The Receiving Party agrees: (i) to take reasonable precautions to protect such Proprietary Information, and (ii) not to use (except in performance of the Services or as otherwise permitted herein) or divulge to any third person any such Proprietary Information. The Disclosing Party agrees that the foregoing shall not apply with respect to any information after five (5) years following the disclosure thereof or any information that the Receiving Party can document (a) is or becomes generally available to the public, or (b) was in its possession or known by it prior to receipt from the Disclosing Party, or (c) was rightfully disclosed to it without restriction by a third party, or (d) was independently developed without use of any Proprietary Information of the Disclosing Party or (e) is required to be disclosed by law.</p>


            <p>6.2. The provided license or the Terms shall not transfer any of our intellectual property rights to you (including the rights to trade-marks or brands or names). We neither provide you with any other license than the license specified explicitly hereby.</p>


            <h3>7. OUR LICENSE TO YOU</h3>
            <p>Subject to these Terms and our policies, we grant you a limited, non-exclusive, non-transferable, without territorial restriction (i.e. worldwide license) and revocable license to use our Products. Should you breach these Terms in any way or had you breached them in the past, we may reject or disable your license provision and use of the services.</p>


            <h3>8. DISCLAIMERS</h3>
            <p>The Products and all included content are provided on an “as is” basis without warranty of any kind, whether express or implied.</p>

            <p>Book2Vid SPECIFICALLY DISCLAIMS ANY AND ALL WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE.</p>

            <p>Book2Vid takes no responsibility and assumes no liability for any User Content that you or any other user or third party posts or transmits using our Products. You understand and agree that you may be exposed to User Content that is inaccurate, objectionable, inappropriate for children, or otherwise unsuited to your purpose.</p>


            <h3>9. LIMITATION OF LIABILITY</h3>
            <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, Book2Vid and Book2Vid Developer SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOOD-WILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM</p>

            <p>(A) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE PRODUCTS;</p>

            <p>(B) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE PRODUCTS, INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES;</p>

            <p>OR (C) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT.</p>

            <p>IN NO EVENT SHALL Book2Vid AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE PRODUCTS EXCEEDING ONE US DOLLARS (100.00 USD)</p>


            <h3>10. ARBITRATION</h3>
            <p>For any dispute you have with Book2Vid, you agree to first contact us and attempt to resolve the dispute with us informally.</p>


            <h3>11. GENERAL TERMS</h3>
            <p>Notification Procedures and changes to these Terms.</p>

            <p>Book2Vid reserves the right to determine the form and means of providing notifications to you, and you agree to receive legal notices electronically if we so choose. We may revise these Terms from time to time and the most current version will always be posted on our website. If a revision, in our sole discretion, is material we will notify you. By continuing to access or use the Products after revisions become effective, you agree to be bound by the revised Terms. If you do not agree to the new terms, please stop using the Products.</p>

            <p>No Waiver.</p>

            <p>No waiver of any term of these Terms shall be deemed a further or continuing waiver of such term or any other term, and Book2Vid’s failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision.Should any of the provisions hereof be ascertained as invalid, ineffective or unenforceable, upon mutual agreement such a provision shall be replaced by a provision whose sense and purpose comes as closely as possible to the original provision. The invalidity, ineffectiveness or unenforceability of one provision shall not affect the validity and effectiveness of the remaining provisions hereof.</p>

            <p>Entire Agreement/Severability.</p>

            <p>These Terms, together with the Privacy Policy and any amendments and any additional agreements you may enter into with Book2Vid in connection with the Products, shall constitute the entire agreement between you and Book2Vid concerning the Products. If any provision of these Terms is deemed invalid, then that provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions of these Terms will remain in full force and effect.</p>
        </View>
    );
}

export default TermsOfService;
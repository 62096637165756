import {
    View
} from '@aws-amplify/ui-react';

function ComingSoon() {
    return (
        <View
        maxWidth="90%" 
        padding="2rem" >
            <h2>Coming Soon!</h2>
            <p>Bood2Vid is currently in Beta. Please check back soon to find out how to sign up.</p>
        </View>
    );
}

export default ComingSoon;